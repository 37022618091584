/*******************************************************************************************
//  * File Name                : Login
//  * File Directory           : src/components/module/authentication/Login
//  * Module                   : Login
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : Home 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Login        -   Login maintenance page
 *
 * #How to call:
 *    <Login/>
 *
 *******************************************************************************************/
import Cookies from 'js-cookie';
import { useState } from "react";
import { useState as useHookState } from "@hookstate/core";
import CustomTextField from "./shared/CustomTextField";
import CustomButton from "./shared/CustomButton";
import ConstantStyles from "../styles/ConstantStyles.json";

import GlobalStates from '../utils/GlobalStates';
// import SiteLogo from '../../../assets/img/megapanalo-logo.svg'

const Login = () => {
    const globalStates = useHookState(GlobalStates);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    // data:{ username: "s@gmail.com", password: "123", type: "email" }

    const handleLoginPassword = () => {
        localStorage.setItem("username", username);
        window.location.href = "/";
    }
    return (
        <div className="login" style={{
            minHeight: "750px"
        }}>
            <div className="login-container">
                {/* <img src={SiteLogo} /> */}

                <div className="login-form">
                    <a href="/" style={{
                        color: ConstantStyles.primary,
                        fontSize: "26px",
                        fontWeight: 700,
                        textAlign: "center",
                        width: "100%",
                        margin: "0 auto",
                        display: "block"
                    }}>
                        {/* <img style={{ padding: "5%" }} src={process.env.PUBLIC_URL + '/megapanalo-logo.svg'} /> */}
                    </a>

                    <center><b>Login</b></center>
                    <>
                        <p style={{ fontSize: "16px", marginBottom: "5px" }}> Email <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                        <CustomTextField
                            value={username}
                            onChange={setUsername}
                            placeholder={"Enter Phone / Email"}
                            type="text"
                            id="email"
                            disabled={isLoggingIn}
                            customSX={{
                                padding: "6px 15px",
                                marginBottom: "20px"
                            }}
                        />
                        <p style={{ fontSize: "16px", marginBottom: "5px" }}>Password <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                        <div style={{ width: "100%" }}>
                            <div style={{ width: "100%", display: "inline-block" }} >
                                <CustomTextField
                                    value={password}
                                    onChange={setPassword}
                                    placeholder={"Password"}
                                    type="password"
                                    id="password"
                                    disabled={isLoggingIn}
                                    customSX={{
                                        padding: "6px 15px",
                                        marginBottom: "10px"
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <CustomButton
                            buttonLabel={isLoggingIn ? "Processing..." : "Login "}
                            onClick={() => handleLoginPassword()}
                            type="submit"
                            disabled={username === "" || password === "" ? true : false}
                            customSX={{
                                width: "100%",
                                padding: "6px 15px",
                                color: ConstantStyles.white
                            }}
                        />
                    </>
                </div>
            </div>
        </div>
    )


}


export default Login;