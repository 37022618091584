import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ChatPage from './ChatPage';
import CustomButton from "./shared/CustomButton";
import GlobalStates from '../utils/GlobalStates';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '80vh'
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '70vh',
        overflowY: 'auto'
    }
});

const Chat = ({ socket }) => {
    const globalStates = useHookState(GlobalStates);
    const classes = useStyles();
    const [selected, setSelected] = useState('');
    const [users, setUsers] = useState([])

    useEffect(() => {
        socket.on("newUserResponse", data => setUsers(data))
        console.log(socket)
    }, [socket, users])

    console.log(socket);

    const handleLogout = () => {
        localStorage.clear()
        // Cookies.set('session-1', "")
        globalStates.alertBody.set("Logging out ...");
        globalStates.alertType.set("success");
        globalStates.alertTitle.set("Success");
        globalStates.showAlert.set(true);

        setTimeout(() => {
            window.location.href = "/";
        }, 2000);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={11} >
                    <Typography variant="h5" className="header-message">Chat Support</Typography>
                </Grid>
                <Grid item xs={1} >
                    <CustomButton
                        buttonLabel={"Logout"}

                        onClick={() => {
                            handleLogout()
                        }}
                        type="submit"
                        customSX={{
                            width: "auto",
                            float: "right",
                            padding: "11px 20px",
                            verticalAlign: "top"
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container component={Paper} className={classes.chatSection}>
                <Grid item xs={3} className={classes.borderRight500}>
                    <List>
                        <ListItem button key="key__1">
                            <ListItemIcon>
                                <Avatar alt={localStorage.getItem("username")} src="" />
                            </ListItemIcon>
                            <ListItemText primary={localStorage.getItem("username")}></ListItemText>
                        </ListItem>
                    </List>
                    <Divider />
                    <Divider />
                    <List>
                        <ListItem button key={"Test"} onClick={() => setSelected("Test")}>
                            <ListItemIcon>
                                <Avatar src="" />
                            </ListItemIcon>
                            <ListItemText primary={"Test"}>{"Test"}</ListItemText>
                            {/* <ListItemText secondary="online" align="right"></ListItemText> */}
                        </ListItem>
                        {users.map((user, index) =>
                            <ListItem button key={user.randomString} onClick={() => setSelected(user.randomString)}>
                                <ListItemIcon>
                                    <Avatar src="" />
                                </ListItemIcon>
                                <ListItemText primary={user.randomString}>{user.randomString}</ListItemText>
                                {/* <ListItemText secondary="online" align="right"></ListItemText> */}
                            </ListItem>
                        )}
                        {/* <ListItem button key="User 1">
                            <ListItemIcon>
                                <Avatar />
                            </ListItemIcon>
                            <ListItemText primary="User 1">User 1</ListItemText>
                        </ListItem> */}
                    </List>
                </Grid>
                <Grid item xs={9}>
                    {!selected ?
                        <>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <center>No chat selected</center>
                        </> :
                        <ChatPage socket={socket} selected={selected} />
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default Chat;