import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import GlobalStates from './utils/GlobalStates';
import Cookies from 'js-cookie';
import Home from "./components/ChatAdmin";
import Login from "./components/Login";
import ThemeStyles from "./styles/ThemeStyles";

import CustomAlert from "./components/shared/CustomAlert";

// import ChatPage from "./components/ChatPage";
import socketIO from "socket.io-client"
import './App.scss';

const socket = socketIO.connect("https://logger.cedrickcampoto.win/chat-admin")
// socket.on("message",function(e){
//   console.log(e);
// })

function App(props) {
  const globalStates = useHookState(GlobalStates);
  const [theme, setTheme] = useState(ThemeStyles);


  useEffect(() => {
    // if (!Cookies.get('session-1') || Cookies.get('session-1') === "") {
    //   localStorage.clear()
    // }

    if (window.screen.width <= 800) {
      globalStates.isMobile.set(true);
    }
    else {
      globalStates.isMobile.set(false);
    }

  }, [window.screen.width]);


  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={props.baseUrl}>
        <div>
          {localStorage.getItem("username") ?
            <Routes>
              <Route path="/" element={<Home socket={socket} />}></Route>

            </Routes>
            :
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          }
        </div>
      </BrowserRouter>
			<CustomAlert />
    </ThemeProvider>
  );
}

export default App;