import React, { useEffect, useState, useRef } from 'react'
import ChatBar from './ChatBar'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'

const ChatPage = ({ socket, selected }) => {
  const [messages, setMessages] = useState([])
  const [typingStatus, setTypingStatus] = useState("")
  const lastMessageRef = useRef(null);
  let randomString = Math.random().toString(36).substr(2, 5)

  // useEffect(() => {
  //   localStorage.setItem("username", randomString)
  //   socket.emit("newUser", { randomString, socketID: socket.id })
  // }, [])
  useEffect(() => {
    console.log(selected)
  }, [selected])
  

  //pag fetch ?
  useEffect(() => {
    socket.on("messageResponse", data => setMessages([...messages, data]))
    // console.log(messages)
    socket.on("message", function (e) {
      setMessages([...messages, e])
      console.log(e);
    })
  }, [socket, messages])

  useEffect(() => {
    socket.on("typingResponse", data => setTypingStatus(data))
  }, [socket])

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="chat">
      <ChatBar socket={socket} />
      <div className='chat__main'>
        <ChatBody selected={selected} messages={messages} typingStatus={typingStatus} lastMessageRef={lastMessageRef} />
        <ChatFooter socket={socket} selected={selected} />
      </div>
    </div>
  )
}

export default ChatPage