import React from 'react'
import { useNavigate } from "react-router-dom"

const ChatBody = ({ messages, typingStatus, lastMessageRef, selected }) => {
  // const navigate = useNavigate()

  console.log(messages)
  // const handleLeaveChat = () => {
  //   localStorage.removeItem("username")
  //   navigate("/")
  //   window.location.reload()
  // }

  return (
    <>
      {/* <header className='chat__mainHeader'>
          <p>Hangout with Colleagues</p>
          <button className='leaveChat__btn' onClick={handleLeaveChat}>LEAVE CHAT</button>
        </header> */}


      <div className='message__container'>
        {messages.map(message => (
          message.name === selected ?
            message.name === localStorage.getItem("username") ? (
              <div className="message__chats" key={message.id}>
                <p className='sender__name'>You </p>
                <div className='message__sender'>
                  <p>{message.text}</p>
                  <p style={{ textAlign: "right", fontSize: "10px" }}>{message.datetime}</p>
                </div>
              </div>
            ) : (
              <div className="message__chats" key={message.id}>
                <p>{message.name}</p>
                <div className='message__recipient'>
                  <p>{message.text}</p>
                  <p style={{ textAlign: "right", fontSize: "10px" }}>{message.datetime}</p>
                </div>
              </div>
            )

            :

            message.adminname === localStorage.getItem("username") &&  message.selected === selected ? (
              <div className="message__chats" key={message.id}>
                <p className='sender__name'>You </p>
                <div className='message__sender'>
                  <p>{message.text}</p>
                  <p style={{ textAlign: "right", fontSize: "10px" }}>{message.datetime}</p>
                </div>
              </div>
            )
            :
            null


        ))}

        <div className='message__status'>
          <p>{typingStatus}</p>
        </div>
        <div ref={lastMessageRef} />
      </div>
    </>
  )
}

export default ChatBody